<template>
  <design-page class="DesignPageCategories">
    <div class="BodyCategoriesSolutions">
      <div class="CenterContainerCategories">
        <div class="TitleHeaderCategories">
          <h1>TODAS NUESTRAS CATEGORIAS</h1>
        </div>
        <div class="ContainerGrid">
          <div v-for="(card, index) in cardList" :key="index">
            <card-categories
              class="CardCategories"
              :tittle="card.tittle"
              :imageUrl="card.imgurl"
            ></card-categories>
          </div>
        </div>
        <div class="UnderContainer">
          <div >
            <router-link to="Catalogue">
            <button id="BtnCategories" class="button is-primary is-rounded">
              ¡Echemos un vistazo a nuestros productos!
            </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </design-page>
</template>

<script>
import DesignPage from "../components/DesignPage.vue";
import CardCategories from "../components/CardCategories.vue";

export default {
  name: "Categories",
  components: {
    DesignPage,
    CardCategories,
  },
  data() {
    return {
      cardList: [
        {
          id: 1,
          tittle: "Lonas",
          imgurl: "IconoLonas.png",
        },
        {
          id: 2,
          tittle: "Vinil",
          imgurl: "IconoVinil.png",
        },
        {
          id: 3,
          tittle: "Telas",
          imgurl: "IconoTelas.png",
        },
        {
          id: 4,
          tittle: "Floor Graphic",
          imgurl: "IconoFloorGraphic.png",
        },
        {
          id: 5,
          tittle: "Iman",
          imgurl: "IconoIman.png",
        },
        {
          id: 6,
          tittle: "Bond",
          imgurl: "IconoPapelBond.png",
        },
        {
          id: 7,
          tittle: "Perforado",
          imgurl: "IconoMicrooperforado.png",
        },
        {
          id: 8,
          tittle: "Textil",
          imgurl: "IconoVinilTextil.png",
        },
        {
          id: 9,
          tittle: "Adherible",
          imgurl: "IconoAdherible.png",
        },
        {
          id: 10,
          tittle: "Tazas",
          imgurl: "IconoTazas.png",
        },
        
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.BodyCategoriesSolutions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110vh;
}
.CenterContainerCategories {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  margin-top: 5%;
}
.TitleHeaderCategories {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: bold;
  font-weight: bold;
  font-size: 1em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  top: 1%;
}
.TitleHeaderCategories h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #dc9942;
}
.ContainerGrid {
  display: grid;
  position: relative;
  grid-template-columns: auto auto auto auto;
  gap: 0px 70px;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 90%;
  height: 90%;
}
.ContainerGrid div {
  text-align: center;
}
.UnderContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
  margin: 5px;
  padding: 5px;
}
#BtnCategories{
  width: 100%;
  height: 55px;
  border: none;
  outline: none;
  margin-top: 3%;
  background-color: #2d3436;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: all 0.25s;
  z-index: 1;
  position: relative;
}
#BtnCategories:hover {
  transition: all 0.55s;
  transform: scale(1.1);
}
#BtnCategories::before {
  transition: all 0.55s;
  transform: scale(0);
}
@media (min-width: 200px) and (max-width: 300px) {
  //SmartPhones Pequeños
  .CenterContainerCategories {
    top: 30px;
    width: 90%;
    height: 85%;
    left: -9.5px;
  }
  .TitleHeaderCategories {
    width: 100%;
  }
  .TitleHeaderCategories h1 {
    font-size: 1em;
  }
  .ContainerGrid {
    grid-template-columns: auto auto;
    gap: 5px 20px;
    width: 100%;
    height: 100%;
  }
  #BtnCategories{
    position: absolute;
    left: 5%;
    width: 90%;
    font-size: 0.7em;
}
  
}
@media (min-width: 300px) and (max-width: 480px) {
  .CenterContainerCategories {
    width: 90%;
    height: 85%;
    top: 5%;
  }
  .TitleHeaderCategories {
    width: 100%;
    top: -5px;
  }
  .TitleHeaderCategories h1 {
    font-size: 1.4em;
  }
  .ContainerGrid {
    grid-template-columns: auto auto;
    gap: 35px 50px;
    width: 100%;
    height: 100%;
    top: 0px;
  }
    #BtnCategories{
    position: absolute;
    width: 90%;
    left: 5%;
    font-size: 0.7em;
}
}
@media (min-width: 480px) and (max-width: 660px) {
  .CenterContainerCategories {
    width: 90%;
    height: 85%;
    top: 3%;
  }
  .TitleHeaderCategories {
    width: 100%;
    top: 1%;
  }
  .TitleHeaderCategories h1 {
    font-size: 1.8em;
  }
  .ContainerGrid {
    grid-template-columns: auto auto auto;
    gap: 10px 25px;
    width: 100%;
    height: 100%;
    top: 2%;
  }
}
@media (min-width: 660px) and (max-width: 980px) {
  .CenterContainerCategories {
    width: 90%;
    height: 85%;
    top: 3%;
  }
  .TitleHeaderCategories {
    width: 100%;
    top: 1%;
  }
  .TitleHeaderCategories h1 {
    font-size: 2.2em;
  }
  .ContainerGrid {
    grid-template-columns: auto auto auto;
    gap: 25px 45px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 980px) and (max-width: 1024px) {
  .CenterContainerCategories {
    width: 90%;
    height: 85%;
    top: 3%;
  }
  .TitleHeaderCategories {
    width: 100%;
    top: 1%;
  }
  .TitleHeaderCategories h1 {
    font-size: 2.7em;
  }
  .ContainerGrid {
    grid-template-columns: auto auto auto;
    gap: 0px 80px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  .ContainerGrid {
    gap: 20px 30px;
  }
}
@media (min-width: 320px) and (max-width: 320px) {
  .CenterContainerCategories {
    top: 5%;
  }
  .TitleHeaderCategories {
    top: 0%;
  }
  .TitleHeaderCategories h1 {
    font-size: 1.1em;
  }
  .ContainerGrid {
    gap: 10px 40px;
  }
}
@media (min-height: 667px) and (max-height: 667px) {
  //IPhone 6,7,8, resolucion especifica
}
</style>
