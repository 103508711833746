<template>
  <div class="Carta">
    <div class="card">
      <div class="card-content">
        <div id="Left">
          <h3 class="Text">
            {{ tittle }}
          </h3>
        </div>
        <div id="Right">
          <div class="content">
            <figure id="Figure">
              <img
                class="Img"
                :alt="titlle"
                :src="require(`../assets/img/${imageUrl}`)"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCategories",
  props: {
    tittle: String,
    imageUrl: String,
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 130px;
  border-radius: 30px;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
}
#Figure{
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.Img{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
@media (min-width: 200px) and (max-width: 300px){
  .card{
    width: 120px;
    height: 70px;
    border-radius: 20px;
  }
  .Text{
    font-size: 0.7em;
  }
  .Img{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 20px;
  }
}
@media (min-width: 300px) and (max-width: 480px){
  .card{
    width: 130px;
    height: 80px;
    border-radius: 30px;
  }
  .Text{
    font-size: 0.8em;
  }
  .Img{
    height: 25px;
  }
}
  @media (min-width: 480px) and (max-width: 660px){
    .card{
      width: 145px;
      height: 95px;
      border-radius: 30px;
    }
    .Text{
      font-size: 0.9em;
    }
    .Img{
      height: 35px;
    }
  }
@media (min-width: 660px) and (max-width: 980px){
  .card{
    width: 200px;
    height: 120px;;
  }
  .Text{
    font-size: 1.3em;
  }
  .Img{
    height: 45px;
  }
}
@media (min-width: 980px) and (max-width: 1024px){
  .card{
    width: 250px;
    height: 140px;
  }
  .Text{
    font-size: 1.3em;
  }
  .Img{
    height: 65px;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  .card {
    width: 130px;
    height: 70px;
    border-radius: 25px;
  }
  .Text{
    font-size: 0.6em;
  }
}
@media (min-width: 320px) and (max-width: 320px) {
  .card{
    width: 120px;
    height: 70px;
    border-radius: 25px;
  }
  .Text{
    font-size: 0.6em;
  }
  .Img{
    width: 100px;
    height: 20px;
  }
}
@media (min-height: 667px) and (max-height: 667px) {
  .card{
    width: 130px;
    height: 70px;
    border-radius: 25px;
  }
  .Text{
    font-size: 0.6em;
  }
}
</style>
